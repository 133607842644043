<template>
    <div class="wrapper padding">
        <div class="preview">
<!--            <h3 class="preview-title ">{{ orgName }}</h3>-->
            <h3 class="preview-title margin-bottom">{{ typeName }}入职信息表</h3>
            <el-form :model="infoForm"
                     label-width="140px"
                     :rules="rules" ref="infoForm" class="add-form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="xingming">
                            <el-input v-model="infoForm.xingming" placeholder="请填写姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="昵称/英文" prop="nicheng">
                            <el-input v-model="infoForm.nicheng" placeholder="请填写昵称/英文"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="xingbie">
                            <el-select v-model="infoForm.xingbie" placeholder="请选择性别" class="w-all">
                                <el-option
                                        v-for="item in sexList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机" prop="shoujihao">
                            <el-input v-model="infoForm.shoujihao" placeholder="请填写手机"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生年月日" prop="chushengnianyueri">
                            <el-date-picker align="center"
                                            class="w-all"
                                            value-format="yyyy-MM-dd"
                                            v-model="infoForm.chushengnianyueri"
                                            type="date"
                                            placeholder="请填写出生年月日">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
                            <el-input v-model="infoForm.shenfenzhenghao" placeholder="请填写身份证/通行证"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="youxiang">
                            <el-input v-model="infoForm.youxiang" placeholder="请填写邮箱"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="个人微信" prop="weixinhao">
                            <el-input v-model="infoForm.weixinhao" placeholder="请填写个人微信"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="家庭地址" prop="jiatingdizhi">
                            <el-input v-model="infoForm.jiatingdizhi" placeholder="请填写家庭地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮编" prop="youbian">
                            <el-input type="number" v-model="infoForm.youbian" placeholder="请填写邮编"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-divider></el-divider>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="毕业学校" prop="biyeyuanxiao">
                            <el-input v-model="infoForm.biyeyuanxiao" placeholder="请填写毕业学校"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="在校专业" prop="zaixiaozhuanye">
                            <el-input v-model="infoForm.zaixiaozhuanye" placeholder="请填写在校专业"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="最高学历" prop="zuigaoxueli">
                            <el-input v-model="infoForm.zuigaoxueli" placeholder="请填写最高学历"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="毕业时间" prop="biyeshijian">
                            <el-date-picker align="center"
                                            class="w-all"
                                            v-model="infoForm.biyeshijian"
                                            type="date"
                                            placeholder="请填写毕业时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="现居住地址" prop="xianjudizhi">
                            <el-input v-model="infoForm.xianjudizhi" placeholder="请填写现居住地址"></el-input>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="最早入职时间" prop="zuizaoruzhishijian">-->
<!--                            <el-date-picker align="center"-->
<!--                                            class="w-all"-->
<!--                                            v-model="infoForm.zuizaoruzhishijian"-->
<!--                                            type="date"-->
<!--                                            placeholder="请填写最早入职时间">-->
<!--                            </el-date-picker>-->

<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="紧急联系人姓名" prop="jinjirenxingming">
                            <el-input v-model="infoForm.jinjirenxingming" placeholder="请填写紧急联系人姓名"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :span="12">
                        <el-form-item label="紧急联系人电话" prop="jinjirendianhua">
                            <el-input v-model="infoForm.jinjirendianhua" placeholder="请填写紧急联系人电话"></el-input>
                        </el-form-item>
                  </el-col>
                  <el-col :span="12">
                        <el-form-item label="与本人关系" prop="yubenrenguanxi">
                            <el-input v-model="infoForm.yubenrenguanxi" placeholder="请填写与本人关系"></el-input>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="银行卡开户行" prop="kaihuhang">-->
<!--                            <el-input v-model="infoForm.kaihuhang" placeholder="请填写银行卡开户行"></el-input>-->
<!--                        </el-form-item>-->

<!--                        <el-form-item label="开户行支行名称" prop="zhihangmingcheng">-->
<!--                            <el-input v-model="infoForm.zhihangmingcheng" placeholder="请填写开户行支行名称"></el-input>-->
<!--                        </el-form-item>-->

<!--                        <el-form-item label="银行卡号" prop="yinhangkahao">-->
<!--                            <el-input v-model="infoForm.yinhangkahao" placeholder="请填写银行卡号"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                </el-row>
                <el-form-item label-width="0" class="flex justify-around">
                    <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存</el-button>
                </el-form-item>
            </el-form>


        </div>

    </div>
</template>

<script>

import rules from "@/utils/rules";
import {zhDate} from "@/utils";
import {getSomeDateUseOfferId, perfectData} from "@/api/staff";
import {sexList} from "@/utils/const";

export default {
    name: "staffInfo",
    data() {
        return {
            id: '',
            type: '',
            orgName: '',
            sexList: sexList,
            infoForm: {
                xingming: '',
                nicheng: '',
                xingbie: '',
                shoujihao: '',
                chushengnianyueri: '',
                shenfenzhenghao: '',
                youxiang: '',
                jiatingdizhi: '',
                youbian: '',
                weixinhao: '',
                biyeyuanxiao: '',
                zaixiaozhuanye: '',
                zuigaoxueli: '',
                biyeshijian: '',
                xianjudizhi: '',
                zuizaoruzhishijian: '',
                jinjirenxingming: '',
                jinjirendianhua: '',
                yubenrenguanxi: '',
                kaihuhang: '',
                zhihangmingcheng: '',
                yinhangkahao: '',
            },
            rules: {
                xingming: rules.mustInput,
                nicheng: rules.mustInput,
                xingbie: rules.mustSelect,
                shoujihao: rules.phone,
                chushengnianyueri: rules.mustSelect,
                shenfenzhenghao: rules.mustInput,
                youxiang: rules.email,
                jiatingdizhi: rules.mustInput,
                youbian: rules.mustInput,
                weixinhao: rules.mustInput,

                biyeyuanxiao: rules.mustInput,
                zaixiaozhuanye: rules.mustInput,
                zuigaoxueli: rules.mustInput,
                biyeshijian: rules.mustDate,
                xianjudizhi: rules.mustInput,
                zuizaoruzhishijian: rules.mustDate,
                jinjirenxingming: rules.mustInput,
                jinjirendianhua: rules.phone,
                yubenrenguanxi: rules.mustInput,
                kaihuhang: rules.mustInput,
                zhihangmingcheng: rules.mustInput,
                yinhangkahao: rules.mustInput,


            },
        }
    },
    created() {
        this.id = this.$route.params.id
        this.type = Number(this.$route.params.type)
        this.getSomeDateUseOfferId()
    },
    computed: {
        typeName() {
            if (this.type === 1) {
                return '全职员工'
            } else if (this.type === 2) {
                return '实习生'
            } else if (this.type === 3) {
                return '兼职员工'
            } else {
                return ''
            }
        }
    },
    components: {},
    methods: {
        //  获取公司信息 以及当前offer 是否填写过
        async getSomeDateUseOfferId() {
            const res = await getSomeDateUseOfferId(this.id)
            this.orgName = res.data.org_name

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sureAdd()
                }
            });
        },
        async sureAdd() {
            let _state = await perfectData(
                this.id,
                this.type,
                this.infoForm.xingming,
                this.infoForm.nicheng,
                this.infoForm.xingbie,
                this.infoForm.shoujihao,
                this.infoForm.chushengnianyueri,
                this.infoForm.shenfenzhenghao,
                this.infoForm.youxiang,
                this.infoForm.jiatingdizhi,
                this.infoForm.youbian,
                this.infoForm.weixinhao,
                this.infoForm.biyeyuanxiao,
                this.infoForm.zaixiaozhuanye,
                this.infoForm.zuigaoxueli,
                zhDate(new Date(this.infoForm.biyeshijian)),
                this.infoForm.xianjudizhi,
                zhDate(new Date(this.infoForm.zuizaoruzhishijian)),
                this.infoForm.jinjirenxingming,
                this.infoForm.jinjirendianhua,
                this.infoForm.yubenrenguanxi,
                this.infoForm.kaihuhang,
                this.infoForm.zhihangmingcheng,
                this.infoForm.yinhangkahao,
            )

            if (_state.status === 200) {
                this.$alert('您已完善基本信息！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        window.location.replace("about:blank");
                        window.close();
                    }
                });
            } else {
                this.$message.error(_state.info)
            }
        },
    },
    filters: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.wrapper {

  width: 1200px;
  margin: 0 auto;

  .preview {
    width: 100%;
    min-height: 500px;
    padding: 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
    background: #fff;

    .preview-title {
      text-align: center;
      line-height: 40px;
      font-size: 26px;
    }
  }
}
</style>
